import { create } from 'zustand';
import { persist } from "zustand/middleware";

type UserStoreState = {
  personFirstname: string;
  personLastname: string;
  email: string;
  phone: string;
  password: string;
  passwordConfirm: string;
  address: string;
  postal_code: string;
  city: string;

  setPersonFirstname: (newPersonFirstname: string) => void;
  setPersonLastname: (newPersonLastname: string) => void;
  setEmail: (newEmail: string) => void;
  setPhone: (newPhone: string) => void;
  setPassword: (newPassword: string) => void;
  setPasswordConfirm: (newPasswordConfirm: string) => void;
  setAddress: (newAddress: string) => void;
  setPostalCode: (newPostalCode: string) => void;
  setCity: (newCity: string) => void;
};

const useFlowUserStore = create<UserStoreState>()(
  persist(
    (set) => ({
      personFirstname: "",
      personLastname: "",
      email: "",
      phone: "",
      password: "",
      passwordConfirm: "",
      address: "",
      postal_code: "",
      city: "",

      setPersonFirstname: (newPersonFirstname) =>
        set({ personFirstname: newPersonFirstname }),
      setPersonLastname: (newPersonLastname) =>
        set({ personLastname: newPersonLastname }),
      setEmail: (newEmail) => set({ email: newEmail }),
      setPhone: (newPhone) => set({ phone: newPhone }),
      setPassword: (newPassword) => set({ password: newPassword }),
      setPasswordConfirm: (newPasswordConfirm) =>
        set({ passwordConfirm: newPasswordConfirm }),
      setAddress: (newAddress) => set({ address: newAddress }),
      setPostalCode: (newPostalCode) => set({ postal_code: newPostalCode }),
      setCity: (newCity) => set({ city: newCity }),
    }),
    {
      name: "flowUserStore",
    }
  )
);

export default useFlowUserStore;
