/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import Header from '../komponents/Header/Header';
import Footer from '../komponents/Footer/Footer';
import LoginComponent from '../komponents/LoginComponent/LoginComponent';

const Login = () => (
  <>
    <Header />
    <LoginComponent />
    <Footer />
  </>
);

export default Login;

export const Head = () => (
  <>
    <meta charSet="utf-8" />
    <meta name="description" content="Login a PuntoTerminal" />
    <title>Login - PuntoTerminal.mx</title>
  </>
);
